import React from 'react';
import { Menu, Row, Col } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import { withAppContext } from '../../store/contextStore';
import BNlogo from '../../images/logo.png';
import { ReactComponent as Dashboard } from '../../images/sidemenu/account.svg';
import { ReactComponent as Log } from '../../images/sidemenu/play.svg';
import { ReactComponent as BmDashboard } from '../../images/sidemenu/bm-dashboard.svg';
import { ReactComponent as BmLog } from '../../images/sidemenu/bm-log.svg';

const SideMenu = props => {
  const url = props.location.pathname;
  return (
    <Row className='fullheight sideMenuDiv'>
      <Col span={ 24 } className='fullheight' >
        <div className='fullheight'>
          <Menu
            mode='inline'
            selectedKeys={ [url] }
            className='sidebar1 fullheight'
          >
            <NavLink to='/app/profileList'>
              <div className='imagecontent'>
                <img
                  src={ BNlogo }
                  alt='BN Icon'
                  className='img'
                  onClick={ () => { props.closeMobileMenu('Dashboard'); } }
                />
              </div>
            </NavLink>
            <div className='imgline' />
            <Menu.Item
              key='/app/profileList'
              className='center-align'
              onClick={ () => {
                props.closeMobileMenu('Dashboard');
              } }
            >
              <NavLink to='/app/profileList'>
                <Row>
                  <Col >
                    { props.menuType === 'mobile' ? <BmDashboard className='colorsvg svg' /> : <Dashboard className='colorsvg svg' /> }
                  </Col>
                </Row>
                <p className='icontext'>
                  Users accounts
                </p>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key='/app/uploadedVideos'
              className='center-align'
              onClick={ () => {
                props.closeMobileMenu('Log Info');
              } }
            >
              <NavLink to='/app/uploadedVideos'>
                <Row>
                  <Col >
                    { props.menuType === 'mobile' ? <BmLog className='colorsvg svg' /> : <Log className='colorsvg svg' /> }
                  </Col>
                </Row>
                <p className='icontext'>
                  Videos
                </p>
              </NavLink>
            </Menu.Item>
          </Menu>
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(withAppContext(SideMenu));
