import React, { Component } from 'react';

class PrivacyPolicy extends Component {
  render() {
   return (
      <div style={{padding: 20}}>
        <p style={{textAlign: 'center'}}><span style={{fontWeight: 'bold'}}>PANDA Mobile Application Privacy Policy&nbsp;</span></p>
        <p>Effective as of December 18, 2020</p>
        <p>This privacy policy (the “Privacy Policy”) applies to the PANDA mobile application (the “App”), owned and operated by University of Pennsylvania PANDA Research Team (“University of Pennsylvania PANDA Research Team,” “we,” “us,” or “our”). We have created this Privacy Policy to tell you what information the App collects, how we use that information, and who we will share that information with, if at all. This Privacy Policy does not address the privacy practices of any third parties that we do not own or control. Capitalized terms not defined in this Privacy Policy will have the meaning set forth in our informed consent form approved by the Institutional Review Board at the University of Pennsylvania and Children’s Hospital of Philadelphia. By visiting and/or using our App, you are agreeing to the terms of this Privacy Policy and the accompanying informed consent form, the consent form language is detailed in the app itself. We encourage you to read the Privacy Policy and to use the information it contains to help you make informed decisions.</p>
        <ol>
          <li><span style={{fontWeight: 'bold'}}>Information We Collect or Receive.</span> In the course of operating the App, we will collect and/or receive the following types of information. You authorize us to collect and/or receive such information.</li>
          <ol type="a">
            <li><span style={{textDecoration: 'underline'}}>Personal Information</span>. We only receive or collect information that identifies you personally if you choose to provide such personally identifiable information to us via email or other means. When you sign up to become a user or contact us, you will be required to provide us with personal information about yourself and your child (collectively, the “Personal Information”). Such Personal Information may include your name, e-mail address,&nbsp; protected health information (PHI), caregiver’s data – gender, race, ethnicity, education, income level; country; zip code; your infant data – date of birth, gender, age, weight, arm length; your infant medical condition; your infant’s pediatrician – name, contact number, videos of your infant, and other similar information. We do not collect any Personal Information from you when you use the App unless you provide us with the Personal Information voluntarily. All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</li>
            <li>Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device’s camera, and related features. If you wish to change our access or permissions, you may do so in your device’s settings. This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.</li>
            <li><span style={{textDecoration: 'underline'}}>Other Information</span>. In addition to the Personal Information, Mobile Device Access, we may automatically collect or receive additional information regarding you and your use of the App; your interactions with us; and information regarding your computer and mobile devices used to access the App (collectively, the “Other Information”). Such <span style={{textDecoration: 'underline'}}>Other Information</span> may include:</li>
            <ol type="i">
              <li><span style={{textDecoration: 'underline'}}>From Your Activity</span>. We may collect or receive information regarding:</li>
              <ol  type="A">
                <li>IP address, which may consist of a static or dynamic IP address and will sometimes point to a specific identifiable computer or mobile device;</li>
                <li>browser type and language;</li>
                <li>referring and exit pages and URLs;</li>
                <li>date and time; and</li>
                <li>details regarding your activity on the App, such as search queries and other performance and usage data.</li>
              </ol>
              <li><span style={{textDecoration: 'underline'}}>About Your Mobile Device</span>. We may collect or receive information regarding:</li>
              <ol type="A">
                <li>type of mobile device;</li>
                <li>operating system and version (e.g., iOS, Android or Windows);</li>
              </ol>
            </ol>
          </ol>
          <li><span style={{fontWeight: 'bold'}}>How Information is Used and Shared.</span></li>
          <ol type="a">
            <li>You authorize us to use the Personal Information, Mobile Device Access, and the Other Information (collectively, the “Information”) to:</li>
            <ol type="i">
              <li>Your information is used by the research team to contact you during the study.</li>
               <li> Your child’s information are used to:</li>
              <ol type="A">
                <li>do the research</li>
                <li>oversee the research</li>
                <li>to see if the research was done right&nbsp;</li>
              </ol>
              <li>your child’s data could be shared for:</li>
              <ol type="A">
                <li>other scientific research</li>
                <li>your child’s medical treatment</li>
              </ol>
              <li>provide and improve our App;</li>
              <li>you authorize members of the research team from CHOP and UPENN to receive your child’s PHI related to this research. CHOP’s Office of Research Compliance and UPENN’s Institutional Review Board are responsible for ensuring your child’s welfare and rights as a research participant.&nbsp;</li>
            </ol>
          
            <li>We will only keep your Information for as long as necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law. When we have no ongoing legitimate business need to process your Information, we will either delete or anonymize such information, or, if this is not possible, then we will securely store your personal information and isolate it from any further processing until deletion is possible. If at any time you wish to opt out you may do so but your data may have already been used for research purposes.&nbsp;</li>
            <li>We engage third-party companies and individuals to perform functions on our behalf. Examples may include providing technical assistance, data management, App hosting, and App development. These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law.</li>
            <li>In an ongoing effort to better understand our users, the App, and our products and services, we may analyze certain Information in anonymized and aggregate form to operate, maintain, manage, and improve the App. This aggregate information does not identify you personally.&nbsp;</li>
            <li>We may share some or all of your Information for scientific, research and educational purposes.&nbsp;</li>
            <li>To the extent permitted by law, we may also disclose the Information:</li>
            <ol type="i">
              <li>In the case of consent: we may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
              <li>for legitimate interests: We may process your data when it is reasonably necessary to achieve our research and educational purposes.</li>
              <li>for legal obligations: when required by law, court order, or other government or law enforcement authority or regulatory agency; or</li>
              <li>Vital Interests: whenever we believe that disclosing such Information is necessary or advisable, for example, to protect the rights, property, or safety of us or others, including you.</li>
          </ol>
          </ol>
          <li><span style={{fontWeight: 'bold'}}>Accessing and Modifying Information and Communication Preferences.</span> If you have provided us any Personal Information, you may access, remove, review, and/or make changes to the same by contacting us as set forth below. We will use commercially reasonable efforts to process such requests in a timely manner. You should be aware, however, that it is not always possible to completely remove or modify information in our database.&nbsp;</li>
          <li><span style={{fontWeight: 'bold'}}>How We Protect Your Information.</span> We are committed to ensuring the security of your Information. We have put in place reasonable physical, technical, and administrative safeguards designed to prevent unauthorized access to, or use of, the Information collected online. We take commercially reasonable steps to protect the Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases or the databases of the third parties with which we may share such Information, nor can we guarantee that the Information you supply will not be intercepted while being transmitted over the Internet. In particular, e-mail sent to us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.</li>
          <li><span style={{fontWeight: 'bold'}}>Important Notices to Non-U.S. Residents.</span> The App and its servers are operated in the United States. If you are located outside of the United States, please be aware that your Information, including your Personal Information, may be transferred to, processed, maintained, and used on computers, servers, and systems located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside the United States and choose to use the App, you hereby irrevocably and unconditionally consent to such transfer, processing, and use in the United States and elsewhere.</li>
          <li><span style={{fontWeight: 'bold'}}>App Stores; External Websites.</span> Your app store (e.g., iTunes or Google Play) may collect certain information in connection with your use of the App, such as Personal Information, Geolocational Information, and other usage-based data. We have no control over the collection of such information by a third-party app store, and any such collection or use will be subject to that third party’s applicable privacy policies.</li>
          <li><span style={{fontWeight: 'bold'}}>Children.</span> This App is intended for use by people aged 13 or older.&nbsp; If you are under 13, please do not send any information about yourself to us, including your name, address, telephone number, or email address.&nbsp; In the event that we learn that we have collected personal information from a minor under age 13 without verification of parental consent, we will delete that information as quickly as possible.&nbsp;</li>
          <li><span style={{fontWeight: 'bold'}}>Changes to This Privacy Policy.</span> This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We reserve the right to modify this Privacy Policy at any time. Any such changes will be posted on the App. By accessing the App after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we collect the Information. Please refer back to this Privacy Policy on a regular basis.</li>
          <li><span style={{fontWeight: 'bold'}}>California Privacy Rights.</span>
          <p><span style={{textDecoration: 'underline'}}>Do Not Track.</span> We do not monitor, recognize, or honor any opt-out or do not track mechanisms, including general web browser “Do Not Track” settings and/or signals.</p>
          </li>
          <li><span style={{fontWeight: 'bold'}}>How to Contact Us.</span> If you have questions about this Privacy Policy, please e-mail us at johnmic@pennmedicine.upenn.edu with “Privacy Policy” in the subject line or mail us at the following address: University of Pennsylvania, Department of Physical Medicine and Rehabilitation, 1800 Lombard Street, 1st Floor Rehab Robotics Lab, Philadelphia, PA 19146, USA. If you have questions about privacy at Penn overall, please email us at <a href="mailto:privacy@uphs.upenn.edu" style={{color: '#0000ff'}}>privacy@uphs.upenn.edu</a>.</li>
        </ol>
      </div>
    );
  }
}

export default PrivacyPolicy;