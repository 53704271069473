import React, { Component } from 'react';
import GooglePlay from '../images/googleplay.png';
import AppStore from '../images/appstore.png';
import { Input, Button, Row, Form, Col } from 'antd';
import {
  isAndroid,isIOS
} from "react-device-detect";

class MobileApp extends Component {

    componentDidMount(){
        if(isAndroid){
           window.location='https://play.google.com/store/apps/details?id=com.pandaapp.android'
        }else if(isIOS){
            window.location='https://apps.apple.com/in/app/panda-mobile/id1542013627'
        }
    }
  render() {
   return (
      <Row>
        <Col
          style={{padding:100,paddingTop:0}}
        >
          <p className='login-text font-20' style={{paddingBottom: 50}}>Welcome to PANDA</p>
          <p style={{textAlign:'justify'}}>Play and NeuroDevelopment Assessement Mobile App by UPenn and CHOP – NIH Grant#: R01- HD-097686.</p>
          <p style={{textAlign:'justify'}}>PANDA MOBILE App allows us to collect video data of infants between 1 and 6 months old.  We will analyze the videos using state of the art algorithms to build screening tools that can be used in life by lay clinicians and caregivers. The study’s goal is to develop an affordable non-invasive easy-to-use tool for early detection of infant neurodevelopmental disabilities so they can receive treatment at an early age.</p>
          <p style={{textAlign:'justify'}}>Significance: In the USA, 1 in 10 infants are born at risk for these disabilities. Early treatment in the first year of life improves long-term outcomes. Today’s clinical tests are however less than adequate. Hard to administer, they require specialized training, and have limited long-term predictive value. There is a critical need for an objective, accurate, easy-to-use tool for early prediction of long-term physical disability. This project’s goal is to develop an automated assessment for early and accurate detection of infants at risk for neurodevelopmental disabilities by capitalizing on state of the art sensing and machine learning technologies. Our project will bring us closer to a universal non-invasive test for early detection of neurodevelopmental disabilities. This test will scale to infants worldwide, producing an affordable tool for infant health assessment.</p>
          <p style={{textAlign:'justify'}}>Objectives: Our project has three aims.  This effort focuses on aim 2 which is to populate a database of infant videos.  We aim to collect at least 1200 unique infant videos (400 with early brain injury, 400 preterm without early brain injury, 400 controls). From the videos we will develop a computer vision algorithm to quantify infant motor performance.</p>
          <p style={{textAlign:'justify',paddingBottom:20}}>Funding: Our project is being funded by the National Institutes of Health – National Institutes of Child Health and Development: R01- HD-097686. We are in partnership with AngelEye Health, a leader in developing cameras for the NICU and monitoring infants</p>
          <a target="_blank"  href="https://play.google.com/store/apps/details?id=com.pandaapp.android"><img src={GooglePlay} className='appstore' /></a>
          <a target="_blank" href="https://apps.apple.com/in/app/panda-mobile/id1542013627"><img src={AppStore} className='appstore' /></a>
        
        </Col>
      </Row>
    );
  }
}

export default MobileApp;