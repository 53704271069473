import React, { Component } from 'react';
import _ from 'lodash';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Auth from './containers/Auth';
import MainPage from './containers/MainPage';
import { withAppContext } from './store/contextStore';
import utils from './utils/Utils';
import PrivacyPolicy from './containers/PrivacyPolicy'

class App extends Component {
  render() {
    const { user } = this.props.context.state;
    const path = this.props.location.pathname;

    if (utils.checkCookie('user') && user && user.userName) {
      if (path === '/login') {
        return (<Redirect to='/app/profileList' />);
      }
    } else if (!(utils.checkCookie('user')) &&
      path !== '/app' && path !== '/login' &&
      path !== '/' && path !== '/forgot_password' && path !== '/privacy_policy' && path !== '/mobile_app'
      && !(_.includes(path, '/reset_password/'))) {
      return (<Redirect to='/login' />);
    }

    return (
      <Switch>
        <Route path='/app' component={ MainPage } />
         <Route path='/privacy_policy' component={ PrivacyPolicy } />
        <Route path='/' component={ Auth } />
        
      </Switch>
    );
  }
}
export default withRouter(withAppContext(App));
