/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @format */

import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Input, Button, Row, Form, Col } from 'antd';
import swal from 'sweetalert';
import { withAppContext } from '../store/contextStore';
import { userLogin } from '../api/login';

class Login extends PureComponent {
  state={
    checked: false,
  };
  componentDidMount() {
    this.props.form.validateFields();
  }

  hasErrors = fieldsError => {
    Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  login = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        userLogin(values).then(result => {
          if (result.success) {
            const user = Object.assign({}, result.data.data.userProfile, { token: result.data.data.accessToken });
            this.props.context.setUser(user, !!this.state.checked);
            this.props.history.push('/app/profileList');
            if (result.data.role && result.data.role.name === 'admin') {
              swal({
                text: 'Admin UI is in progress',
                icon: 'success',
                button: 'Ok',
              });
            }
          } else if (Array.isArray(result.errors)) {
            swal({
              text: `${result.errors[0]}`,
              icon: 'error',
              button: 'Ok',
            });
          } else {
            const error = _.get(result.errors, 'message.error', false);
            const errMess = _.get(result.errors, 'message.message', false);
            let text;
            if (error) {
              text = error;
            } else if (errMess) {
              text = errMess;
            }
            swal({
              text,
              icon: 'error',
              button: 'Ok',
            });
          }
        });
        // const user = {
        //   email: 'tripura.c@imaginnovate.com',
        //   password: '1234',
        //   firstName: 'tripura',
        //   lastName: 'cherukuri',
        // };
        // this.props.context.setUser(user, false);
        // this.props.history.push('/app/profileList');
      }
    });
  };

  handleOnChange = (element, value) => {
    this.props.form.setFieldsValue({
      [element]: value,
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;
    const emailError = isFieldTouched('email') && getFieldError('email');
    const passwordError =
      isFieldTouched('password') && getFieldError('password');
    return (
      <Row className='flex-center'>
        <Col
          lg={ { span: 12, offset: 6 } }
          md={ { span: 12, offset: 6 } }
          sm={ 24 }
          xs={ 24 }
        >

          <p className='login-text font-20'>Welcome to Panda</p>
          <Form onSubmit={ this.login }>
            <Form.Item
              validateStatus={ emailError ? 'error' : '' }
              help={ emailError || '' }
            >
              { getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid E-mail!',
                  },
                ],
              })(<Input
                className='input-base'
                placeholder='Username'
                onChange={ e => this.handleOnChange('email', e.target.value) }
                autoComplete='email'
              />) }
            </Form.Item>
            <Form.Item
              validateStatus={ passwordError ? 'error' : '' }
              help={ passwordError || '' }
            >
              { getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your password!' },
                ],
              })(<Input
                className='input-base'
                type='password'
                placeholder='Password'
                autoComplete='new-password'
                onChange={ e =>
                    this.handleOnChange('password', e.target.value)
                  }
              />) }
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                disabled={ this.hasErrors(getFieldsError()) }
              >
                <span className='login-now'>LOGIN NOW</span>
              </Button>
            </Form.Item>
          </Form>
              <Link to='/forgot_password' className='forgetpassword-Label'>
                Forgot your password?
              </Link>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'login' })(withAppContext(Login));
