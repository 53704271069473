import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

class BaseModal extends Component {
  render() {
    return (
      <Modal
        visible
        destroyOnClose={ this.props.destroyOnClose }
        footer={ null }
        onCancel={ this.props.onCancel }
        width={ this.props.width }
        maskClosable={ false }
        title={ this.props.title }
        className={ this.props.className }
      >
        { this.props.children }
      </Modal>
    );
  }
}

BaseModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  destroyOnClose: PropTypes.bool,
  width: PropTypes.string,
};

BaseModal.defaultProps = {
  destroyOnClose: true,
  width: '70%',
};

export default BaseModal;
