import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Row, Col, Table, Button, Spin, Input, Select, DatePicker, Icon } from 'antd';
import { withAppContext } from '../store/contextStore';
import BaseCard from '../components/common/BaseCard';
import { getAllBabyVideos } from '../api/users';
import AppConfig from '../config/AppConfig';
import BaseModal from '../components/common/BaseModal';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import JSZipUtils from 'jszip-utils';
import _ from 'lodash';
import { CSVLink } from 'react-csv';



const { Option } = Select;
const { RangePicker } = DatePicker;

const urlToPromise = url => new Promise(((resolve, reject) => {
  JSZipUtils.getBinaryContent(url, (err, data) => {
    if (err) {
      reject(err);
    } else {
      resolve(data);
    }
  });
}));

class UploadedVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      measurementType: ['Imperial', 'Metric'],
      gestationalAge: ['Full Term', 'Early', 'Late', 'Unknown'],
      dateRange: [],
      startDate: null,
      endDate: null,
      videoFilePath: '',
      showModal: false,
      searchType: 0,
      inputText: '',
      videos: [],
      videosCSV: [],
      allVideos: [],
      selectedBabyKeys: [],
      columns: [
        {
          title: 'CareTaker Name',
          dataIndex: 'careTakerUserName',
          key: 'careTakerUserName',
          className: 'pointer'
        },
        {
          title: 'Baby Name',
          dataIndex: 'babyName',
          key: 'babyName',
          filterMultiple: false,
          onFilter: (value, record) => record.babyName.indexOf(value) === 0,
          sorter: (a, b) => (a && a.babyName && a.babyName.length) - (b && b.babyName && b.babyName.length),
          sortDirections: ['descend', 'ascend'],
          className: 'pointer'
        },
        {
          title: 'Video File Name',
          dataIndex: 'videoName',
          key: 'videoName',
          filterMultiple: false,
          onFilter: (value, record) => record.videoName.indexOf(value) === 0,
          sorter: (a, b) => (a && a.videoName && a.videoName.length) - (b && b.videoName && b.videoName.length),
          sortDirections: ['descend', 'ascend'],
          className: 'pointer'
        },
        {
          title: 'Uploaded Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          filterMultiple: false,
          onFilter: (value, record) => record.createdAt.indexOf(value) === 0,
          sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
          sortDirections: ['descend', 'ascend'],
          className: 'pointer'
        },
        {
          title: 'Age In Weeks',
          dataIndex: 'ageInWeeks',
          key: 'ageInWeeks',
          className: 'pointer'
        },
        {
          title: 'Measurement Type',
          dataIndex: 'measurementType',
          key: 'measurementType',
          className: 'pointer'
        },
        {
          title: 'Weight',
          dataIndex: 'weight',
          key: 'weight',
          className: 'pointer'
        },
        {
          title: 'Forearm Length',
          dataIndex: 'foreArmLength',
          key: 'foreArmLength',
          filterMultiple: false,
          className: 'pointer'
        },
        {
          title: 'Gestational Age',
          dataIndex: 'gestationalAge',
          key: 'gestationalAge',
          className: 'pointer'
        },
        {
          title: 'Actions',
          dataIndex: '',
          key: 'action',
          className: 'pointer',
          render: (text, record) => (
            <span>
              <Icon
                style={{ marginRight: 20 }}
                type='eye'
                className='view'
                onClick={() => { this.setState({ showModal: true, videoFilePath: record.videoPath && record.videoPath.videoPath && record.videoPath.videoPath }); }}
              />
              <a href={record.videoPath.videoPath} download='video.mov'>
                <Icon
                  type='download'
                  className='view'
                />
              </a>
            </span>
          ),
        },
      ],
    };
  }

  onSelectChange = selectedBabyKeys => {
    this.setState({ selectedBabyKeys });
  };


  componentDidMount() {
    this.getAllBabyVideos();
  }

  onDelete = (id, e) => {
    e.preventDefault();
    this.props.history.push(`/app/profiledetails/${id}`);
  };

  getAllBabyVideos = () => {
    const { measurementType, gestationalAge } = this.state;
    this.setState({ loading: true });
    getAllBabyVideos().then(result => {
      if (result.success) {
        const videos = [];
        const videosCSV = [];
        result.data.videoList.map((item, key) => {
          const babies = item.babies;
          const videodetails = {
            key,
            babyId: babies && babies.id,
            careTakerId: babies && babies.user && babies.user.id,
            createdAt: moment(item.createdAt).format(AppConfig.date_format2),
            babyName: babies && babies.babyName,
            babyId: babies && babies.id,
            careTakerId: babies && babies.user && babies.user.id,
            careTakerUserName: babies && babies.user && babies.user.careTakerUserName,
            ageInWeeks: item.ageInWeeks,
            weight: item.weight ? (item.measurementType == '1' ? item.weight + ' lbs' : item.weight + ' kg') : '',
            gestationalAge: babies && gestationalAge[babies.gestationalAge - 1],
            foreArmLength: item && item.foreArmLength ? (item.measurementType == '1' ? item.foreArmLength + ' in' : item.foreArmLength + ' cm') : '',
            measurementType: item && measurementType[item.measurementType - 1],
          }
          const obj = Object.assign({}, item, videodetails);
          videos.push(obj);
          delete videodetails.key;
          videodetails.videoURL = item.videoPath.videoPath;
          videodetails.filename = item.videoPath.filename;
          videosCSV.push(videodetails)
        });
        this.setState({ videos, allVideos: videos, videosCSV, loading: false });
      } else {
        this.setState({ loading: false });
      }
    })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  handleDate = () => {
    if (this.state.startDate && this.state.endDate) {
      const data = this.state.allVideos;
      const startDate = new Date(moment(this.state.startDate).format(AppConfig.date_format2));
      const endDate = new Date(moment(this.state.endDate).format(AppConfig.date_format2));
      const resultProductData = data.filter(a => {
        let hitDates = [a.createdAt];
        hitDates = hitDates.map(date => new Date(date));
        const hitDateMatches = hitDates.filter(date => date >= startDate && date <= endDate);
        return hitDateMatches.length > 0;
      });
      this.setState({ videos: resultProductData, selectedBabyKeys: [] });
    } else {
      this.setState({ videos: this.state.allVideos, selectedBabyKeys: [] });
    }
  };

  handleDateRange = e => {
    const dates = e;
    this.setState({ dateRange: dates, startDate: dates && dates[0], endDate: dates && dates[1] }, () => {
      this.handleDate();
    });
  };

  handleSearchText = value => {
    this.setState({ inputText: value.trim() });
    if (value === '') {
      this.setState({ videos: this.state.allVideos, selectedBabyKeys: [] });
    } else {
      const data = this.state.videos;
      const filteredData = data.filter(entry =>
        (entry.babyName && entry.babyName.includes(this.state.inputText)));
      this.setState({ videos: filteredData, selectedBabyKeys: [] });
    }
  };

  handleSearchType = val => {
    this.setState({ searchType: val });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  downloadVideo = (videoPath, name) => {
    // let link = document.createElement("a");
    // // link.download = 'filename.mp4';
    // // link.target = "_blank";
    // // Construct the URI
    // link.href = videoPath;
    // document.body.appendChild(link);
    // setTimeout(function() {
    //       link.click();
    //       // Cleanup the DOM
    //       document.body.removeChild(link);
    //       // DOWNLOAD_COMPLETED = true;
    //       // document.getElementById('nextButton').onclick();
    //   }, 500);

    // setTimeout(() => {
    //   const response = {
    //     file: 'http://releases.ubuntu.com/12.04.5/ubuntu-12.04.5-alternate-amd64.iso',
    //   };
    //   // server sent the url to the file!
    //   // now, let's download:
    //   window.open(videoPath);
    //   // you could also do:
    //   // window.location.href = response.file;
    // }, 100);

    // const windowReference = window.open();
    // windowReference.location = videoPath;

    window.location.assign(videoPath);
  };
  downloadVideosByzip(all) {
    this.setState({ loading: true });
    let videos = all == 1 ? this.state.allVideos : this.state.selectedBabyKeys
    const zip = new JSZip();
    _.map(videos, (data, i) => {
      let row = this.state.allVideos[all == 1 ? i : data]
      const { videoPath, fileName } = row.videoPath
      zip.file(fileName, urlToPromise(videoPath), { binary: true });
    });
    zip.generateAsync({ type: 'blob' }, (metadata) => {
    })
      .then((blob) => {
        saveAs(blob, `BabyVideos_` + Date.now() + `.zip`);
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      }
      ).catch((e) => {
        this.setState({ loading: false });
      })
  }
  renderVideoModal = () => (
    <BaseModal
      visible
      onCancel={this.closeModal}
      width='40%'
      className='videoModal'
    >
      {
        this.state.videoFilePath ? <ReactPlayer
          url={this.state.videoFilePath}
          className='react-player'
          playing
          width='100%'
          height='100%'
          controls
        />
          : <p className='text-center font-18 react-player'>No video found.</p>
      }
    </BaseModal>
  );

  render() {
    const { searchType, dateRange, selectedBabyKeys, videosCSV } = this.state;
    const rowSelection = {
      selectedBabyKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div>
        <Row className='dashboardpage'>
          <Col xs={22} offset={1}>
            <Row className='waterlog'>
              <BaseCard
                title='Panda web console'
              />
            </Row>
            <Row className='waterlog bg-none'>
              <Row className='watermargin p-0 uploadedVideos'>
                <Spin spinning={this.state.loading}>

                  <div className='mb-20' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='d-flex' style={{ width: '50%' }}>
                      <Button
                        style={{ marginRight: 16, backgroundColor: '#67dbb4' }}
                        className='name-search-input'
                        disabled={!selectedBabyKeys.length}
                        onClick={() => this.downloadVideosByzip(0)}
                      ><span>{"Download Selected(" + selectedBabyKeys.length + ")"}</span>
                      </Button>
                      <Button
                        className='name-search-input'
                        style={{ backgroundColor: '#67dbb4' }}
                        onClick={() => this.downloadVideosByzip(1)}
                      > Download All
                    </Button>

                      <Row className='justify-end p-0'>
                        <CSVLink data={videosCSV} filename={`baby_videos_${moment().format('DD/MM/YYYY')}.csv`}>
                          <Button style={{ height: '45px', marginLeft: 16, backgroundColor: '#67dbb4' }}>
                            <Icon type="export" /> Export All
                  </Button>
                        </CSVLink>
                      </Row>

                    </div>
                    <div className='d-flex' style={{ width: '50%' }}>
                      <Select value={this.state.searchType} onChange={this.handleSearchType}>
                        <Option value={0}>Baby name</Option>
                        <Option value={1}>Uploaded date</Option>
                      </Select>
                      {
                        searchType === 0 ?
                          <Input
                            className='name-search-input'
                            placeholder='Search'
                            value={this.state.inputText}
                            onChange={e => { this.handleSearchText(e.target.value); }}
                          />
                          :

                          <RangePicker
                            className='calender-range-picker'
                            value={dateRange}
                            format={AppConfig.date_format2}
                            onChange={e => { this.handleDateRange(e); }}
                          />
                      }
                    </div>
                  </div>
                  <Table
                    rowSelection={rowSelection}
                    columns={this.state.columns}
                    dataSource={this.state.videos}
                    onRow={(r) => ({
                      onClick: () => {
                        this.setState({ showModal: true, videoFilePath: r.videoPath && r.videoPath.videoPath && r.videoPath.videoPath });
                      }
                    })}
                  />
                </Spin>
              </Row>
            </Row>
          </Col>
        </Row>
        {(this.state.showModal) &&
          this.renderVideoModal()
        }
      </div>
    );
  }
}
export default withRouter(withAppContext(UploadedVideos));
