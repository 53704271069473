import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { Input, Button, Row, Col, Form } from 'antd';
import {forgotPassword} from '../api/login'

class ForgetPassword extends Component {
  componentDidMount() {
    this.props.form.validateFields();
  }

  hasErrors=fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  forgetPassword=e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        forgotPassword(values)
          .then(result => {
            if (result.success) {
              swal({
                text: `${result.data.message}`,
                icon: 'success',
                button: 'Ok',
              });
              this.props.history.push('/login');
            } else {
              if (Array.isArray(result.errors)) {
                swal({
                  text: `${result.errors[0]}`,
                  icon: 'error',
                  button: 'Ok',
                });
              } else {
                swal({
                  text: `${result.errors.message.error}`,
                  icon: 'error',
                  button: 'Ok',
                });
              }
              this.props.form.resetFields();
            }
          });
      }
    });
  };

  handleOnChange = (element, value) => {
    this.props.form.setFieldsValue({
      [element]: value,
    });
  };

  render() {
    const {
      getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
    } = this.props.form;
    const emailError = isFieldTouched('email') && getFieldError('email');
    return (
      <Row className='flex-center'>
        <Col lg={ { span: 12, offset: 6 } } md={ { span: 12, offset: 6 } } sm={ 24 } xs={ 24 }>
          <p className='login-text forgot-text font-20'>Forgot your password?</p>
          <Form onSubmit={ this.forgetPassword }>
            <Form.Item validateStatus={ emailError ? 'error' : '' } help={ emailError || '' }>
              { getFieldDecorator('email', {
          rules: [
          {
            required: true,
            message: 'Please input your email!',
          },
          {
            type: 'email',
            message: 'Please enter a valid E-mail!',
          },
        ],
           })(<Input
             className='input-base'
             placeholder='Email Address'
             onChange={ e => this.handleOnChange('email', e.target.value) }
             autoComplete='email'
           />) }
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' disabled={ this.hasErrors(getFieldsError()) }>
                <span className='login-now'>RECOVER MY PASSWORD</span>
              </Button>
            </Form.Item>
          </Form>
          <Link
            to='/'
            className='forgetpassword-Label'
          >Go back
          </Link>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'ForgetPassword' })(ForgetPassword);
