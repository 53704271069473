import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Layout, Row, Col } from 'antd';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import SideMenu from '../components/common/SideMenu';
import MobileNav from '../components/common/MobileNavBar';
import AppRoutes from '../config/AppRoutes';
import { withAppContext } from '../store/contextStore';

const { Sider, Content } = Layout;

class MainPage extends PureComponent {
  /* to resolve callback props functionality */
  closeMobileNav = () => {
  };
  render() {
    const { pathname } = this.props.location;

    if (this.props.context.state.user) {
      return (
        <Layout style={ { height: '100vh' } }>
          <Row>
            <Col xs={ 0 } sm={ 0 } md={ 24 } lg={ 24 } className='desktop-menu'>
              <Sider width={ 140 } >
                <SideMenu closeMobileMenu={ this.closeMobileNav } />
              </Sider>
            </Col>
          </Row>
          <Layout >
            {
            AppRoutes.map(route => (
               (_.includes(pathname, route.path) &&
               <MobileNav title={ route.name } />
            )))
          }
            <Content className='contentstyle' >
              <Switch >
                {
                AppRoutes.map(route => (
                  <Route
                    exact={ route.exact }
                    path={ route.path }
                    component={ route.component }
                    key={ route.key }
                  />
                ))
              }
              </Switch>
            </Content>
          </Layout>
        </Layout>
      );
    } else {
      return (
        <Redirect to='/login' />

      );
    }
  }
}

export default withAppContext(MainPage);
