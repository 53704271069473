import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function usersProfilesList() {
  const url = ApiUrl('user/listofAccounts');
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}


export function caretakerProfileList() {
  const url = ApiUrl('caretaker/userProfileList');
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' }}, true);
}

export function getCareTaker(id) {
  const url = ApiUrl(`caretaker/getcaretakers/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function getCareTakerBabiesList(id) {
  const url = ApiUrl(`babies/babieslist/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function getBabyDetails(babyId) {
  const url = ApiUrl(`/babies/getBabyDetails/${babyId}`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function getBabyVideos(babyId) {
  const url = ApiUrl(`video/getbabievideos/${babyId}`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function getAllBabyVideos() {
  const url = ApiUrl('/video/list');
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function userDetails() {
  const url = ApiUrl('/user/userDetails');
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}


export function resetPassword(data) {
  const url = ApiUrl('user/resetPassword');
  return Call('put', url, data, ParseGeneralResponse, { responseDataKeys: { data: '' } });
}

export function deleteAccount(id) {
  const url = ApiUrl('user/removeAccount?id='+id);
  return Call('delete', url, {}, ParseGeneralResponse, { responseDataKeys: {data:''  } },true);
}

export function getCountries() {
  const url = ApiUrl('countries/');
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } });
}
