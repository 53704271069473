import React from 'react';
import { Row, Divider } from 'antd';

function HorizontalDivider() {
  return (
    <Row>
      <div > <Divider className='horizontal-divider' /></div>
    </Row>
  );
}
export default HorizontalDivider; 