exports.getParamId = props => props.match.params.id;
exports.getParam = (props, param) => props.match.params[param];
const setCookie = (cname, cvalue, remember) => {
  const d = new Date();
  let expires;
  if (remember) {
    d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
    expires = `expires=${d.toUTCString()}`;
  } else {
    expires = 'expires=';
  }
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

const getCookie = cname => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const Inputtxt = input => {
  const data = input.trim();
  if (data.length > 500) {
    return 'Should not exceed 500 characters.';
  } else {
    return true;
  }
};

exports.checkCookie = cname => {
  const user = getCookie(cname);
  if (user !== '') {
    return true;
  }
  return false;
};

exports.setCookie = setCookie;
exports.getCookie = getCookie;
exports.Inputtxt = Inputtxt;

