import React, { PureComponent } from 'react';
import { Row, Col, Icon, Avatar } from 'antd';
import { withRouter } from 'react-router-dom';
import SideMenu from './SideMenu';
import AppConfig from '../../config/AppConfig';
import { withAppContext } from '../../store/contextStore';
import HorizontalDivider from './HorizontalDivider';
import BaseModal from '../../components/common/BaseModal';
import ChangePassword from '../../containers/ChangePassword';

class MainPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordModal: false,
      listView: false,
      visible: false,
      pageTitle: props.title,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  onLogOut = () => {
    this.props.context.dropUser();
    this.props.history.push('/login');
  };

  changePassword = () => {
    this.setState({ showPasswordModal: true });
  };

  OnPasswordModalClose = () => {
    this.setState({ listView: false }, () => {
      this.setState({ showPasswordModal: false });
    });
  };

  closeMobileNav = pageTitle => {
    this.setState({ pageTitle }, () => {
      this.setState({ listView: false });
    });
  };

  goToProfile = () => {
    this.setState({ listView: false, pageTitle: 'My Profile' }, () => {
      this.props.history.push('/app/profile');
    });
  };

  renderPasswordModal = () => (
    <BaseModal
      title='Update'
      visible
      onCancel={ this.OnPasswordModalClose }
      width='40%'
    >

      <ChangePassword
        passwordModalCancel={ this.OnPasswordModalClose }
      />

    </BaseModal>
  );

  renderImageModal() {
    const { user } = this.props.context.state;
    return (
      <BaseModal
        visible={ this.state.visible }
        onCancel={ this.handleCancel }
        width='50%'
      >
        <div className='imageModal'>
          <Avatar
            shape='square'
            size={ 35 }
            src={ user.profileImage &&
                     `${AppConfig.apiUrl}/${user.profileImage}` }
            className='clientProfile modal'
          />
        </div>
      </BaseModal>
    );
  }

  render() {
    const { user } = this.props.context.state;
    return (
      <Row >
        <Col
          xs={ 24 }
          sm={ 24 }
          md={ 0 }
          lg={ 0 }
        >
          <Row
            className='waterlog topNav'
          >
            <Row className='watermargin'>
              <Row className='menuDivision'>
                <Icon type={ this.state.listView ? 'close' : 'menu' } className='pointer' onClick={ () => { this.setState({ listView: !this.state.listView }); } } />
                <Row className='menu-text-row'>
                  <Col xs={ 24 }><div className='pageName'>{ this.state.pageTitle }</div></Col>
                </Row>
              </Row>
              <Row className={ `center-align menuDivision listView ${this.state.listView && 'active'}` }>
                <Col xs={ 22 }>
                  <div className='profileDropDown'>
                    <Row className='profile'>
                      <Col xs={ 2 } className='text-left d-flex'>
                        <div className='profile'>
                          <Avatar
                            size={ 35 }
                            src={ user.profileImage && `${AppConfig.apiUrl}/${user.profileImage}` }
                            onClick={ this.showModal }
                          />
                        </div>
                      </Col>
                      <Col
                        xs={ 21 }
                        className='text-left d-flex align-center'
                        onClick={ () => { this.setState({ profileListView: !this.state.profileListView }); } }
                      >
                        <span className='dashboard-coach-name-dropdown text-wrap'>{ user.firstName } { user.lastName }</span>
                        <Icon
                          type={ `${this.state.profileListView ? 'up' : 'down'}` }
                          className='dashboard-myprofile-arrow'
                        />
                      </Col>
                    </Row>
                    <div
                      className={ `listView ${this.state.profileListView && 'active'}` }
                      onClick={ () => { this.setState({ profileListView: !this.state.profileListView }); } }
                    >
                      <ul>
                        <li onClick={ () => { this.goToProfile(); } }>
                          <Row>
                            <Col xs={ { span: 20 } }>
                              <span className='text-wrap' >My Profile</span>
                            </Col>
                            <Col xs={ { span: 2, offset: 2 } } className='dashboard-dropdown-align justify-end'>
                              <Icon type='right' />
                            </Col>
                          </Row>
                        </li>
                        <HorizontalDivider />
                        <li onClick={ () => this.changePassword() }>
                          <Row>
                            <Col
                              xs={ { span: 20 } }
                            >
                              <span className='text-wrap'>Change Password</span>
                            </Col>
                            <Col xs={ { span: 2, offset: 2 } } className='dashboard-dropdown-align justify-end'>
                              <Icon type='right' />
                            </Col>
                          </Row>
                        </li>
                        <HorizontalDivider />
                        <li onClick={ () => this.onLogOut() }>Logout</li>
                      </ul>
                    </div>
                  </div>
                  <SideMenu menuType='mobile' closeMobileMenu={ title => { this.closeMobileNav(title); } } />
                </Col>
              </Row>
            </Row>
          </Row>
        </Col>
        { (this.state.showPasswordModal) &&
          this.renderPasswordModal()
        }
        { this.state.visible &&
          this.renderImageModal()
        }
      </Row>
    );
  }
}

export default withRouter(withAppContext(MainPage));
