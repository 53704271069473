import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import swal from 'sweetalert';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Row, Col, Table, Icon, Spin, Button } from 'antd';
import { withAppContext } from '../store/contextStore';
import BaseCard from '../components/common/BaseCard';
import { usersProfilesList, deleteAccount, caretakerProfileList } from '../api/users';
import AppConfig from '../config/AppConfig';


const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
  },
  getCheckboxProps: record => ({
    name: record.name,
  }),
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
      countries: [],
      usersCSV: [],
      EthnicitiesList: [],
      RaceList: [],
      countriesList: [],
      educationList: [],
      incomeList: [],
      columns: [
        {
          title: 'Username',
          dataIndex: 'careTakerUserName',
          key: 'careTakerUserName',
          className: 'pointer'
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          filterMultiple: false,
          onFilter: (value, record) => record.email.indexOf(value) === 0,
          sorter: (a, b) => a.email.length - b.email.length,
          sortDirections: ['descend', 'ascend'],
          className: 'pointer'
        },
        {
          title: 'Videos Uploaded',
          dataIndex: 'videosUploaded',
          key: 'videosUploaded',
          className: 'pointer'
        },
        {
          title: 'Joined At',
          dataIndex: 'createdAt',
          key: 'createdAt',
          className: 'pointer'
        },
        {
          title: 'Actions',
          dataIndex: '',
          key: 'action',
          render: (text, record) => (
            <span>
              <Icon
                style={{ marginRight: 20 }}
                type='eye'
                className='view'
                onClick={e => { this.onView(record.id, e); }}
              />
              <Icon
                type='delete'
                className='view'
                onClick={e => { this.onDelete(record.id, e); }}
              />
            </span>
          ),
          className: 'pointer'
        },
      ],
    };
  }

  componentDidMount() {
    this.getCareTakerProfileList();

  }

  getCareTakerProfileList = () => {
    this.setState({ loading: true });
    caretakerProfileList().then(result => {

      let list = result.data.data;
      this.setState({
        EthnicitiesList: list.EthnicitiesList,
        RaceList: list.RaceList,
        countriesList: list.countriesList,
        educationList: list.educationList,
        incomeList: list.incomeList
      }, () => {
        this.getProfilesList();
       
      })

    })
      .catch(err => {
        this.setState({ loading: false },
          () => {
            this.getProfilesList();
          });
      });
  };

  onView = (id, e) => {
    e.preventDefault();
    this.props.history.push(`/app/profiledetails/${id}`);
  };

  onDelete = (id, e) => {
    e.preventDefault();
    swal({
      title: "",
      text: "Are you sure you want to delete this account? This action cannot be undone.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    })
      .then((willDelete) => {
        if (willDelete) {
          this.setState({ loading: true });
          deleteAccount(id).then(result => {
            this.setState({ loading: false });
            if (result.success) {
              this.getProfilesList();
            } else {
              let message = _.get(result, 'errors.message.message', '')
              swal(message, {
                icon: "error",
              });
            }
          })
            .catch(err => {
              this.setState({ loading: false });
            });
        }
      });

  };
  onChange(pagination, filters, sorter, extra) {
  }

  getProfilesList = () => {
    this.setState({ loading: true });
    usersProfilesList().then(result => {
      if (result.success) {
        const { EthnicitiesList, RaceList, countriesList, educationList, incomeList } = this.state;
        const users = [];
        const usersCSV = [];
        result.data.data.map(item => {

          const obj = Object.assign({}, item, { createdAt: moment(item.createdAt).format(AppConfig.date_format2) });

          users.push(obj);
          
        });
        this.setState({ users, loading: false });
        this.addCsvData();

      } else {
        this.setState({ loading: false });
      }
    })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  addCsvData() {
    const { EthnicitiesList, RaceList, countriesList, educationList, incomeList } = this.state;
    const genders= [{id:1, name: 'Male'}, {id:2, name: 'Female'}, {id:3,name:'Unknown'}, {id:4,name:'Not specified'},{ id:5,name:'Not reported'}]
    const promises = [];
    this.state.users.map((item) => {
      const userIncome= incomeList.filter(x=>x.id==item.income);
      const userCountry = countriesList.filter(x=>x.id == item.country );
      const userGender = genders.filter(x=>x.id==item.gender);
      const userEthnicity = EthnicitiesList.filter(x=> x.id == item.ethnicity);
      const userEducation = educationList.filter(x => x.id == item.education);
      const userRaces = RaceList.filter(x=>x.id==item.race);
      promises.push(new Promise(async (resolve) => {
        const csvObj = {
          id: item.id,
          Name: item.userName,
          Email: item.email,
          IncomeLevel: userIncome.length? userIncome[0].income:'',
          PhoneNumber: item.phoneNumber,
          ZipCode: item.careTakerZipCode,
          Country: userCountry.length ? userCountry[0].name:'' ,
          VideosUploaded: item.videosUploaded,
          Gender: userGender.length?userGender[0].name:'',
          Race: userRaces.length?userRaces.map(x=>x.race).toString():'',
          Ethnicity:userEthnicity.length? userEthnicity[0].ethnicity:'',
          EducationLevel: userEducation.length ? userEducation[0].education:'',
          CreatedAt: moment(item.createdAt).format(AppConfig.date_format2)
        };
        resolve(csvObj);
      }));
    });
    Promise.all(promises).then((usersCSV)=>{
      this.setState({
        usersCSV
      })
    })

  }


  render() {
    const { users, usersCSV } = this.state;
    return (
      <div>
        <Row className='dashboardpage'>
          <Col xs={22} offset={1}>
            <Row className='waterlog'>
              <BaseCard
                title='Panda web console'
              />
            </Row>
            <Row className='waterlog bg-none'>
              <Row className='watermargin justify-end p-0'>
                <CSVLink data={usersCSV} filename={`users_accounts_list_${moment().format('DD/MM/YYYY')}.csv`}>
                  <Button className='videoBtns'>
                    <Icon type="export" /> Export All
                  </Button>
                </CSVLink>
              </Row>
                {/* <CSVLink style={{display:'none'}} ref={(r) => this.csvLink = r} data={usersCSV} filename={`baby_accounts_list_${moment().format('DD/MM/YYYY')}.csv`}>
                  <Button className='videoBtns'>
                    <Icon type="export" /> Export All
                  </Button>
                </CSVLink> */}
              <Row className='watermargin p-0'>
                <Spin spinning={this.state.loading}>
                  <Table
                    // future use
                    // rowSelection={{
                    //   type: 'checkbox',
                    //   ...rowSelection,
                    // }}
                    dataSource={users}
                    columns={this.state.columns}
                    onChange={this.onChange}
                    onRow={(record) => ({
                      onClick: (e) => this.onView(record.id, e)
                    })}
                  />
                </Spin>
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(withAppContext(Dashboard));
