import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, Avatar, Dropdown, Menu, Icon } from 'antd';
import { withAppContext } from '../../store/contextStore';
import AppConfig from '../../config/AppConfig';
import imagePlaceholder from '../../images/user_default.png';
import BaseModal from '../../components/common/BaseModal';
import ChangePassword from '../../containers/ChangePassword';

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordModal: false,
      visible: false,
      notifications: { count: 0 },
    };
  }

  componentDidMount() {
  }
  onLogOut = () => {
    this.props.context.dropUser();
    localStorage.setItem('remember', false);
  };

  getProfile=() => {
    this.props.history.push('/app/profile');
  };

  changePassword = () => {
    this.setState({ showPasswordModal: true });
  };

  OnPasswordModalClose = () => {
    this.setState({ showPasswordModal: false });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  renderPasswordModal = () => (
    <BaseModal
      visible
      onCancel={ this.OnPasswordModalClose }
      width='40%'
    >
      <ChangePassword
        passwordModalCancel={ this.OnPasswordModalClose }
      />
    </BaseModal>
  );

  renderImageModal() {
    const { user } = this.props.context.state;
    return (
      <BaseModal
        visible={ this.state.visible }
        onCancel={ this.handleCancel }
        width='50%'
      >
        <div className='imageModal'>
          <Avatar
            shape='square'
            size={ 35 }
            src={ !_.isEmpty(user.profileImage) ?
                    `${AppConfig.apiUrl}/${user.profileImage}`
                      :
                      imagePlaceholder }
            className='clientProfile modal'
          />

        </div>
      </BaseModal>
    );
  }

  render() {
    const { user } = this.props.context.state;
    const { title, subText } = this.props;
    const menu = (
      <Menu>
        <Menu.Item key='0'onClick={ () => { this.getProfile(); } } className='dashboard-menu-label'>
            <Row>
              <Col xs={ 22 }>
                <div>My Profile</div>
              </Col>
              <Col xs={ 2 }>
                <Icon type='right' />
              </Col>
            </Row>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='1'onClick={ () => this.changePassword(true) } className='dashboard-menu-label'>
            <Row>
              <Col xs={ 22 }>
                <div>Change Password</div>
              </Col>
              <Col xs={ 2 }>
                <Icon type='right' />
              </Col>
            </Row>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='3' className='dashboard-menu-label' onClick={ () => { this.onLogOut(); } }>Logout</Menu.Item>
      </Menu>
    );
    return (
      <div>
        <Row className='watermargin'>
          <Row className='waterMargin-row'>
            <Col xs={ 24 } md={ 16 } sm={ 24 } className='head_left'>
              <Row >
                <Row className='d-flex'>
                  { this.props.navigate &&
                  <Icon
                    type='left'
                    onClick={ () => this.props.history.push(this.props.navigate) }
                  /> }
                  <p className={ title === 'Log review' ? 'dashboard-header-left-align mb-0 coachDesc' : 'dashboard-header-left-align mb-0' }>{ title }</p>
                </Row>
                <p className='dashboard-info-left-align'>{ subText }</p>
              </Row>
            </Col>
            <Col xs={ 0 } md={ 8 } sm={ 0 } className='borderleft dashboardCard myclientsDropdown'>
              <Row className='dashboard-dropdown-align dasboard-padding' >
                <div className='profile-div'>
                  <Avatar
                    size={ 35 }
                    src={ user.profileImage ? `${AppConfig.apiUrl}/${user.profileImage}` : imagePlaceholder }
                    onClick={ () => { this.showModal(); } }
                  />
                </div>
                <Col xs={ 21 }>
                  <Dropdown overlay={ menu } trigger={ ['click'] } >
                    <a className='ant-dropdown-link d-flex align-center' href='#'>
                      <span className='dashboard-coach-name-dropdown'>{ user.userName } </span>
                      <Icon type='down' className='dashboard-myprofile-arrow' />
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
        { (this.state.showPasswordModal) &&
          this.renderPasswordModal()
        }
        { this.state.visible &&
        this.renderImageModal()
      }
      </div>
    );
  }
}

export default withRouter(withAppContext(Card));
