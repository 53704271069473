import React, { Component } from 'react';
import { Row, Col, Form, Spin } from 'antd';
import UserProfileField from '../components/common/UserProfileFields';
import { withAppContext } from '../store/contextStore';
import { userDetails } from '../api/users';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: '',
      loading: false,
      user: props.user ? props.user : {
        userName: { name: 'Username', value: '', valueFor: 'userName' },
        email: { name: 'Email', value: '', valueFor: 'email' },
      },
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    this.setState({ loading: true });
    userDetails().then(result => {
      if (result.success) {
        this.setState({ loading: false });
        const userData = result.data.data.userProfile;
        const { user } = this.state;
        user.userName.value = userData.userName;
        user.email.value = userData.email;
        this.setState({ user });
      } else {
        this.setState({ loading: false });
      }
    })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  handleChange=(value, valueFor) => {
    const updateuser = this.state.user;
    updateuser[valueFor].value = value;
    this.setState({ user: updateuser });
  };

  handleSave=(name, description) => {
  };

  handleDiscard= name => {
    const data = this.state.userData;
    const { user } = this.state;
    user[name].value = data[name];
    this.setState({ user });
  };
  render() {
    const data = Object.values(this.state.user);
    return (
      <div>
        <Row className='profile-user-loading'>
          <Spin spinning={ this.state.loading }>
            <Col xs={ { offset: 1, span: 22 } } lg={ 22 }>
              <Form>
                <Row className='waterlog'>
                  <Row className='watermargin'>
                    <div className='profile-main'>
                      <Form.Item>
                        <Col xs={ { span: 23 } } >
                          <p className='myprofile-info font-20'>Profile Info</p>
                        </Col>

                      </Form.Item>
                    </div>
                    <Form.Item>
                      { data.map(field => (<UserProfileField
                        question={ field.name }
                        answer={ field.value }
                        valueFor={ field.valueFor }
                        handleChange={ this.handleChange }
                        handleSave={ this.handleSave }
                        handleDiscard={ this.handleDiscard }
                      />)) }
                    </Form.Item>
                  </Row>
                </Row>
              </Form>
            </Col>
          </Spin>
        </Row>
      </div>
    );
  }
}
export default Form.create({ name: 'UserPuserprofilerofile' })(withAppContext(UserProfile));
