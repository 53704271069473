import React, { Component } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Row, Col } from 'antd';
import logo from '../images/logo.png';
import Login from './Login';
import ForgetPassword from './ForgetPassword';
import ResetPassword from './ResetPassword';
import MobileApp from './MobileApp'

const AppRoutes = [
  {
    key: '000', exact: true, path: '/login', component: Login,
  },
  {
    key: '001', exact: true, path: '/forgot_password', component: ForgetPassword,
  },
  {
    key: '002', exact: true, path: '/reset_password/:id', component: ResetPassword,
  },
   {
    key: '003', exact: true, path: '/mobile_app', component: MobileApp,
  },
];

class Auth extends Component {
  render() {
    return (
      <Row className='login'>
        <Col xs={ { span: 24 } } md={ { span: 18, offset: 3 } } className='centerAlign loginbackground'>
          <img
            src={ logo }
            alt='logo'
            className='BN-Logos'
          />
          <Switch>
            {
              AppRoutes.map(route => (
                <Route
                  exact={ route.exact }
                  path={ route.path }
                  component={ route.component }
                  key={ route.key }

                />
              ))
            }
            <Redirect from='/' to='/login' />
          </Switch>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Auth);
