import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function userLogin(data) {
  const url = ApiUrl('user/signInUser');
  return Call('post', url, data, ParseGeneralResponse, { responseDataKeys: { data: '' } });
}

export function forgotPassword(data) {
  const url = ApiUrl('user/forgotPassword');
  return Call('post', url, data, ParseGeneralResponse, { responseDataKeys: { data: '' } });
}

export function changePassword(data) {
  const url = ApiUrl('user/changePassword');
  return Call('put', url, data, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}
