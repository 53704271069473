import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import { Row, Col, Card, Spin, Button, Tabs, Table, Icon } from 'antd';
import { withAppContext } from '../store/contextStore';
import BaseCard from '../components/common/BaseCard';
import { getCareTaker, getCareTakerBabiesList, getBabyDetails, getBabyVideos } from '../api/users';
import AppConfig from '../config/AppConfig';
import BaseModal from '../components/common/BaseModal';

const { TabPane } = Tabs;

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
  },
  getCheckboxProps: record => ({
    name: record.name,
  }),
};

class ProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: [
        'Male', 'Female', 'Unknown', 'Not specified', 'Not reported',
      ],
      measurementType: ['Imperial', 'Metric'],
      gestationalAge: ['Full Term', 'Early', 'Late', 'Unknown'],
      tabValue: '1',
      showModal: false,
      loading: false,
      caregivers: [],
      caregiver: 1,
      caregiverProfile: {
        careTakerUserName: { name: 'Primary care taker', value: '', valueFor: 'careTakerUserName' },
        gender: { name: 'Gender', value: '', valueFor: 'gender' },
        email: { name: 'Email', value: '', valueFor: 'email' },
        phoneNumber: { name: 'Phone Number', value: '', valueFor: 'phoneNumber' },
      },
      babyProfile: {
        babyName: { name: 'Baby Name', value: '', valueFor: 'babyName' },
        gender: { name: 'Gender', value: '', valueFor: 'gender' },
        dob: { name: 'Dob', value: '', valueFor: 'dob' },
        ageInWeeks: { name: 'Age In Weeks', value: '', valueFor: 'ageInWeeks' },
        measurementType: { name: 'Measurement Type', value: '', valueFor: 'measurementType' },
        weight: { name: 'Weight', value: '', valueFor: 'weight' },
        foreArmLength: { name: 'Forearm Length', value: '', valueFor: 'foreArmLength' },
        MedicalCondition: { name: 'Medical Condition', value: '', valueFor: 'MedicalCondition' },
        gestationalAge: { name: 'Gestational Age', value: '', valueFor: 'gestationalAge' },
        pediatritionsName: { name: 'Pediatritions Name', value: '', valueFor: 'pediatritionsName' },
        pediatricianPhoneNumber: { name: 'Pediatrician Phone Number', value: '', valueFor: 'pediatricianPhoneNumber' },
      },
      babies: [{ name: 'Select baby' }],
      baby: 0,
      profileInfo: {},
      babyVideos: [],
      columns: [
        {
          title: 'CareTaker Name',
          dataIndex: 'careTakerUserName',
          key: 'careTakerUserName',
          className:'pointer'
        },
        {
          title: 'Baby Name',
          dataIndex: 'babyName',
          key: 'babyName',
          filterMultiple: false,
          onFilter: (value, record) => record.babyName.indexOf(value) === 0,
          sorter: (a, b) => (a && a.babyName && a.babyName.length) - (b && b.babyName && b.babyName.length),
          sortDirections: ['descend', 'ascend'],
          className:'pointer'
        },
        {
          title: 'Video File Name',
          dataIndex: 'videoName',
          key: 'videoName',
          filterMultiple: false,
          onFilter: (value, record) => record.videoName.indexOf(value) === 0,
          sorter: (a, b) => (a && a.videoName && a.videoName.length) - (b && b.videoName && b.videoName.length),
          sortDirections: ['descend', 'ascend'],
          className:'pointer'
        },
        {
          title: 'Uploaded Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          filterMultiple: false,
          onFilter: (value, record) => record.createdAt.indexOf(value) === 0,
          sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
          sortDirections: ['descend', 'ascend'],
          className:'pointer'
        },
        {
          title: 'Age In Weeks',
          dataIndex: 'ageInWeeks',
          key: 'ageInWeeks',
          className:'pointer'
        },
        {
          title: 'Measurement Type',
          dataIndex: 'measurementType',
          key: 'measurementType',
          className:'pointer'
        },
        {
          title: 'Weight',
          dataIndex: 'weight',
          key: 'weight',
          className:'pointer'
        },
        {
          title: 'Forearm Length',
          dataIndex: 'foreArmLength',
          key: 'foreArmLength',
          filterMultiple: false,
          className:'pointer'
        },
        {
          title: 'Gestational Age',
          dataIndex: 'gestationalAge',
          key: 'gestationalAge',
          className:'pointer'
        },
        {
          title: 'Actions',
          dataIndex: '',
          key: 'action',
          className: 'actionBtns pointer',
          render: (text, record) => (
            <span>
              <Icon
              style={{ marginRight: 20 }}
              type='eye'
              className='view'
              onClick={() => { this.setState({ showModal: true, videoFilePath: record.videoPath && record.videoPath.videoPath }); }}
            />
            <Icon
              type='download'
              className='view'
              onClick={() => { this.downloadVideo(record.videoPath.videoPath, record.videoName); }}
            />
            </span>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    this.getCareTaker();
    this.getCareTakerBabiesList();
  }

  getCareTaker = () => {
    const genderArr = this.state.gender;
    this.setState({ loading: true });
    getCareTaker(this.props.match.params.id).then(result => {
      if (result.success) {
        const {data} = result.data;
        const { careTakerUserName, gender, email, phoneNumber } = data;
        const { caregiverProfile } = this.state;
        caregiverProfile.careTakerUserName.value = careTakerUserName;
        caregiverProfile.gender.value = gender && genderArr[gender - 1];
        caregiverProfile.email.value = email;
        caregiverProfile.phoneNumber.value = phoneNumber;
        if (this.state.caregivers.length === 0) {
          this.state.caregivers.push(result.data.data);
        }
        this.setState({
          loading: false, profileInfo: caregiverProfile, caregivers: this.state.caregivers, caregiverProfile, caregiver: data.id,
        });
      } else {
        this.setState({ loading: false });
      }
    })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  getBabyDetails = () => {
    const genderArr = this.state.gender;
    const measurementTypeArr = this.state.measurementType;
    const gestationalAgeArr = this.state.gestationalAge;
    this.setState({ loading: true });
    getBabyDetails(this.state.baby).then(result => {
      if (result.success) {
        const { data } = result.data;
        const { babyName, gender, dob, ageInWeeks, weight, foreArmLength, MedicalCondition, measurementType, gestationalAge, pediatritionsName, pediatricianPhoneNumber } = data;
        const { babyProfile } = this.state;
        babyProfile.babyName.value = babyName;
        babyProfile.gender.value =  gender && genderArr[gender - 1];
        babyProfile.dob.value =  dob && moment(dob).format(AppConfig.date_format2);
        babyProfile.ageInWeeks.value = ageInWeeks;
        babyProfile.weight.value = weight ? (measurementType == '1' ? weight + ' lbs' : weight + ' kg') : '';
        babyProfile.foreArmLength.value = foreArmLength && (measurementType == '1' ? foreArmLength + ' in' : foreArmLength + ' cm')
        babyProfile.MedicalCondition.value = MedicalCondition;
        babyProfile.measurementType.value = measurementType && measurementTypeArr[measurementType - 1];
        babyProfile.gestationalAge.value = gestationalAge && gestationalAgeArr[gestationalAge - 1];
        babyProfile.pediatritionsName.value = pediatritionsName;
        babyProfile.pediatricianPhoneNumber.value = pediatricianPhoneNumber;
        this.setState({ loading: false, profileInfo: babyProfile, babyProfile });
      } else {
        this.setState({ loading: false });
      }
    })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  getBabyVideos = () => {
    const { measurementType, gestationalAge } = this.state;
    this.setState({ loading: true });
    getBabyVideos(this.state.baby).then(result => {
      if (result.success) {
        const babyVideos = [];
        result.data.videoList.map((item, key) => {
          const babies = item.babies;
          const obj = Object.assign({}, item, {
            createdAt: moment(item.createdAt).format(AppConfig.date_format2),
            babyName: babies && babies.babyName,
            careTakerUserName: babies && babies.user && babies.user.careTakerUserName,
            ageInWeeks: babies && babies.ageInWeeks,
            weight: babies && babies.weight ? (babies.measurementType == '1' ? babies.weight + ' lbs' : babies.weight + ' kg') : '',
            gestationalAge: babies && gestationalAge[babies.gestationalAge-1],
            foreArmLength: babies && babies.foreArmLength ? (babies.measurementType == '1' ? babies.foreArmLength + ' in' : babies.foreArmLength + ' cm') : '',
            measurementType: babies && measurementType[babies.measurementType-1],
          });
          babyVideos.push(obj);
        });
        this.setState({ babyVideos, loading: false });
      } else {
        this.setState({ loading: false });
      }
    })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  getCareTakerBabiesList = () => {
    getCareTakerBabiesList(this.props.match.params.id).then(result => {
      if (result.success) {
        this.setState({ babies: result.data.babyList });
      }
    })
      .catch(err => {
      });
  };

  getTab = key => {
    this.setState({ tabValue: key });
    if (key === '1') {
      this.getBabyDetails();
    } else if (key === '2') {
      this.getBabyVideos();
    }
  };

  handleChange = (val, type, opt) => {
    this.setState({ [type]: val, [opt]: 0 }, () => {
      if (this.state.baby !== 0) {
        this.getTab(this.state.tabValue);
      } else if (this.state.caregiver !== 0) {
        this.setState({ tabValue: '1' });
        this.getCareTaker();
      }
    });
  };

  onChange(pagination, filters, sorter, extra) {
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  downloadVideo = (videoPath, name) => {
    window.location.assign(videoPath);
  };

  renderVideoModal = () => (
    <BaseModal
      visible
      onCancel={this.closeModal}
      width='40%'
      className='videoModal'
    >
      {
        this.state.videoFilePath ? <ReactPlayer
          url={this.state.videoFilePath}
          className='react-player'
          playing
          width='100%'
          height='100%'
          controls
        />
          : <p className='text-center font-18 react-player'>No video found.</p>
      }
    </BaseModal>
  );

  render() {
    const {
      caregivers, caregiver, babies, baby, profileInfo, babyVideos,
    } = this.state;
    const data = Object.values(profileInfo);
    return (
      <div>
        <Row className='dashboardpage'>
          <Col xs={22} offset={1}>
            <Row className='waterlog'>
              <BaseCard
                title='Panda web console'
                navigate='/'
              />
            </Row>
            <Row className='waterlog bg-none profileDetails'>
              <Col xs={5} className='profile-left-card detail-card'>
                <Row className='watermargin pb-0'>
                  <p className='font-18 mb-10'>Caregiver Profile</p>
                  {caregivers.map((field, key) => (<div className={`${field.id === caregiver && 'side-btn-green '} side-btn-opt`} onClick={() => { this.handleChange(key + 1, 'caregiver', 'baby'); }}>{field.careTakerUserName}</div>))}
                </Row>
                <hr />
                <Row className='watermargin pb-0 pt-0'>
                  <p className='font-18 mb-10'>Baby Profiles</p>
                  {babies && babies.length > 0 ? babies.map((field, key) => (<div className={`${field.id === baby && 'side-btn-green '} side-btn-opt`} onClick={() => { this.handleChange(field.id, 'baby', 'caregiver'); }}>{field.babyName}</div>)) : <div>No babies found.</div>}
                </Row>
              </Col>
              <Col xs={17} offset={1}>
                <Row className='watermargin'>
                  <Tabs defaultActiveKey='1' type='card' size='small' onChange={this.getTab}>
                    {
                      (caregiver !== 0 || baby !== 0) &&
                      <TabPane
                        tab='Profile'
                        key='1'
                      >
                        <Spin spinning={this.state.loading}>
                          <Card
                            size='small'
                            className='detail-card mb-20 uploadedVideos'
                          >
                            {
                              data && data.length > 0 &&
                              data.map(field => (
                                <Row className='cardFields'>
                                  <Col xs={12} ><p>{`${field.name} :`}</p></Col>
                                  <Col xs={12} ><p className='feildData'>{field.value}</p></Col>
                                </Row>
                              ))
                            }
                          </Card>
                        </Spin>
                      </TabPane>
                    }
                    {
                      (baby !== 0) &&
                      <TabPane
                        tab='Videos'
                        key='2'
                      >
                        <Row className='watermargin p-0 uploadedVideos'>
                          <Spin spinning={this.state.loading}>
                            <Table
                              rowSelection={{
                                type: 'radio',
                                ...rowSelection,
                              }}
                              onRow={(r) => ({
                                onClick: () => { 
                                  this.setState({ showModal: true, videoFilePath: r.videoPath && r.videoPath.videoPath });
                                }
                              })}
                              dataSource={babyVideos}
                              columns={this.state.columns}
                              onChange={this.onChange}
                            />
                          </Spin>
                        </Row>
                      </TabPane>
                    }
                  </Tabs>
                  {(this.state.showModal) &&
                    this.renderVideoModal()
                  }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(withAppContext(ProfileDetails));
