import React, { Component } from 'react';
import moment from 'moment';
import utils from '../utils/Utils';

export const AppContext = React.createContext('app');

const initialState = {
  user: '',
  logDate: moment(),
  feedbackData: [],
};

export class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    if (utils.checkCookie('user')) {
      try {
        this.setUser(JSON.parse(utils.getCookie('user')), JSON.parse(localStorage.getItem('remember')));
      } catch {
        this.dropUser();
        localStorage.setItem('remember', false);
      }
    }
  }

  setUser = (user, remember) => {
    this.setState({ user }, () => {
        const userData = this.state.user;
        const obj = Object.assign({id: userData.id} , {email: userData.email},  {userName: userData.userName},{token: userData.token}, {videoTutorialWatched: userData.videoTutorialWatched});
        utils.setCookie('user', JSON.stringify(obj), remember);
    });
  };

  setLogDate = date => {
    this.setState({
      logDate: date,
    });
  };

  setFeedBack = data => {
    this.setState({
      feedbackData: data,
    });
  };

  setMessageId = id => {
    this.setState({
      messageId: id,
    });
  };

  dropUser = () => new Promise(resolve => {
    this.setState(initialState, () => {
      utils.setCookie('user', '', false);
    });
    this.setState(initialState, () => {
      resolve();
    });
  });

  render() {
    const {
      user, logDate, feedbackData, messageId,
    } = this.state;
    return (
      <AppContext.Provider
        value={ {
          state: {
                user, logDate, feedbackData, messageId,
              },
          setUser: this.setUser,
          dropUser: this.dropUser,
          setLogDate: this.setLogDate,
          setFeedBack: this.setFeedBack,
          setMessageId: this.setMessageId,
        } }
      >
        { this.props.children }
      </AppContext.Provider>
    );
  }
}

export function withAppContext(GivenComponent) {
  return function WrapperComponent(props) {
    return (
      <AppContext.Consumer>
        { state => <GivenComponent { ...props } context={ state } /> }
      </AppContext.Consumer>
    );
  };
}
