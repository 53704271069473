import React, { PureComponent } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Input, Button, Row, Form, Col, message, Spin } from 'antd';
import { changePassword } from '../api/login';

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.props.form.validateFields();
  }

  hasErrors=fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  changePassword=e => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        changePassword(values)
          .then(result => {
            if (result.success) {
              this.setState({
                loading: false,
              });
              message.success(`${result.data.message}`, 1, () => {
                this.props.history.push('/login');
              });
            } else {
              this.setState({
                loading: false,
              });
              const error = _.get(result.errors, 'message.error', false);
              const errMess = _.get(result.errors, 'message.message', false);
              let text;
              if (error) {
                text = error;
              } else if (errMess) {
                text = errMess;
              }
              message.error(`${text}`, 2);
              this.props.form.resetFields();
            }
          });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Two passwords that you entered is inconsistent!');
    } else {
      callback();
    }
  };

  handleOnChange = (element, value) => {
    this.props.form.setFieldsValue({
      [element]: value,
    });
  };

  render() {
    const {
      getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
    } = this.props.form;
    const passwordError = isFieldTouched('currentPassword') && getFieldError('currentPassword');
    const newPasswordError = isFieldTouched('newPassword') && getFieldError('newPassword');
    const confirmPasswordError = isFieldTouched('confirmPassword') && getFieldError('confirmPassword');

    return (
      <Row className='changepassword'>
        <Spin spinning={ this.state.loading }>

          <Col className='pwdbackground' >
            <p className='password-head font-20'>Change Password</p>
            <p className='changepassword-text'>Please reset your password below.</p>
            <Form onSubmit={ this.changePassword } className='passwordform'>
              <Form.Item
                validateStatus={ passwordError ? 'error' : '' }
                help={ passwordError || '' }
                hasFeedback
              >
                { getFieldDecorator('currentPassword', {
               rules: [
                {
                 required: true,
                 message: 'Please enter your old password!',
                }],
              })(<Input
                className='password-input'
                type='password'
                placeholder='Current Password'
                autoComplete='new-password'
                onChange={ e => this.handleOnChange('currentPassword', e.target.value) }
              />) }
              </Form.Item>
              <Form.Item
                validateStatus={ newPasswordError ? 'error' : '' }
                help={ newPasswordError || '' }
                hasFeedback
              >
                { getFieldDecorator('newPassword', {
               rules: [
                 {
                  required: true,
                  message: 'Please enter your new password!',
                 },
                 {
                    validator: this.validateToNextPassword,
                 },
             ],
             })(<Input
               className='password-input'
               type='password'
               placeholder='New Password'
               autoComplete='new-password'
               onChange={ e => this.handleOnChange('newPassword', e.target.value) }
             />) }
              </Form.Item>
              <Form.Item
                validateStatus={ confirmPasswordError ? 'error' : '' }
                help={ confirmPasswordError || '' }
                hasFeedback
              >
                { getFieldDecorator('confirmPassword', {
              rules: [
              {
                required: true,
                message: 'Please confirm your password!',
             },
             {
                validator: this.compareToFirstPassword,
             },
            ],
            })(<Input
              className='password-input'
              type='password'
              onBlur={ this.handleConfirmBlur }
              placeholder='Confirm new password'
              autoComplete='new-password'
              onChange={ e => this.handleOnChange('confirmPassword', e.target.value) }
            />) }
              </Form.Item>
              <Form.Item>
                <Button htmlType='submit' className='savepasswordButton' disabled={ this.hasErrors(getFieldsError()) }>
                  <span className='savepassword'>Save new password</span>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Spin>
      </Row>
    );
  }
}

export default Form.create({ name: 'login' })(withRouter(Login));
