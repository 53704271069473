export default {
  baseUrl: 'https://panda-upenn.org',
  perPage: 10,
  date_format: 'DD/MM/YYYY',
  date_format2: 'MM/DD/YYYY',
  data_time_format: 'DD/MM/YYYY HH:mm A',
  time_format: 'HH:mm:ss',
  dateTimeFormat: 'MMM DD, YYYY hh:mm A',
  apiUrl: 'https://panda-upenn.org/api',
};
