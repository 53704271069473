import Dashboard from '../containers/Dashboard';
import UserProfile from '../containers/UserProfile';
import ChangePassword from '../containers/ChangePassword';
import ProfileDetails from '../containers/ProfileDetails';
import UploadedVideos from '../containers/UploadedVideos';

const AppRoutes = [
  {
    key: '000', exact: true, path: '/app/profileList', name: 'My Dashboard', component: Dashboard,
  },
  {
    key: '001', exact: true, path: '/app/profile', name: 'My Profile', component: UserProfile,
  },
  {
    key: '002', exact: true, path: '/app/changepassword', name: 'Change Password', component: ChangePassword,
  },
  {
    key: '003', exact: true, path: '/app/profiledetails/:id', name: 'Profile Details', component: ProfileDetails,
  },
  {
    key: '004', exact: true, path: '/app/uploadedVideos', name: 'Uploaded Videos', component: UploadedVideos,
  },
];
export default AppRoutes;
