import axios from 'axios/index';
import _ from 'lodash';
import swal from 'sweetalert';
import AppConfig from '../config/AppConfig';
import utils from '../utils/Utils';

export function PageParams(page, perPage) {
  return `page=${page || 1}&per_page=${perPage || AppConfig.perPage}`;
}

export function ApiUrl(path) {
  return `${AppConfig.baseUrl}/api/${path}`;
}

export function HttpErrorMessage(error) {
  const status = error.response ? error.response.status : '';
  if (_.get(error, 'response.data.errors', null)) {
    return {
      message: error.response.data.errors,
      code: status,
    };
  } else if (_.get(error, 'response.data', null)) {
    return {
      message: error.response.data,
      code: status,
    };
  }
  return [error.message, status];
}

export function ParseGeneralResponse(success, response, options) {
  const result = { success };
  if (!_.isEmpty(options.return)) {
    result.return = options.return;
  }
  if (success) {
    const dataKeys = options.responseDataKeys || {};
    _.forEach(dataKeys, (value, key) => {
      const valueKey = _.isEmpty(value) ? 'data' : `data.${value}`;
      result[key] = _.get(response, valueKey, null);
    });
  } else {
    result.errors = HttpErrorMessage(response);
  }
  return result;
}

export function Call(method, url, data, parseCallback, options = {}, authorization) {
  const dataObj = {
    method,
    url,
    data,
  };
  if (authorization) {
    try {
      const { token } = JSON.parse(utils.getCookie('user'));
      dataObj.headers = {
        'x-auth-token': `${token}`,
      };
    } catch {
      utils.setCookie('user', '', false);
      localStorage.setItem('remember', false);
    }
  }

  return new Promise(resolve => {
    axios(dataObj)
      .then(response => {
        resolve(parseCallback(true, response, options));
      })
      .catch(async error => {
        if (error.response.status === 401) {
          await utils.setCookie('user', '', false);
          swal({
            text: 'Your session has expired.Please login!',
            icon: 'error',
            button: 'Ok',
          })
            .then(() => {
              window.location.href = '/login';
            });
        }
        resolve(parseCallback(false, error, options));
      });
  });
}
