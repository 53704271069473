import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Row, Col, Input, DatePicker, Select } from 'antd';
import AppConfig from '../../config/AppConfig';
import utils from '../../utils/Utils';

const { Option } = Select;

class UserProfileFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      valueFor: props.valueFor,
      mobileNumberErr: '',
      Err: '',
      genderErr: '',
      gender: ['Select gender', 'Male', 'Female', 'Others'],
    };
  }

  getAnswers = (valueFor, answer) => {
    if (valueFor === 'dob') {
      return this.props.answer && moment(this.props.answer).format(`${AppConfig.date_format2}`);
    } else if (valueFor === 'gender') {
      return this.state.gender[this.props.answer];
    } else if (valueFor === 'mobileNumber') {
      return this.props.answer;
    } else {
      return answer;
    }
  };

  handleSave = () => {
    if (this.state.valueFor === 'mobileNumber') {
      const data = this.phonenumber(this.props.answer);
      if (data === true) {
        this.setState({ edit: false, mobileNumberErr: '' });
        this.props.handleSave(this.state.valueFor, this.props.answer);
      } else {
        this.setState({ mobileNumberErr: data });
      }
    } else if (this.state.valueFor === 'gender') {
      if (this.props.answer !== 0) {
        this.setState({ edit: false, genderErr: '' });
        this.props.handleSave(this.state.valueFor, this.props.answer);
      } else {
        this.setState({ genderErr: 'Please select gender' });
      }
    } else if (this.state.valueFor === 'firstName' || this.state.valueFor === 'lastName' || this.state.valueFor === 'hobbies') {
      const data = this.NameInput(this.props.answer);
      if (data === true) {
        this.setState({ edit: false, mobileNumberErr: '' });
        this.props.handleSave(this.state.valueFor, this.props.answer);
      } else {
        this.setState({ mobileNumberErr: data });
      }
    } else if (this.state.valueFor === 'address' || this.state.valueFor === 'shortDescription' ||
            this.state.valueFor === 'quote') {
      const data = utils.Inputtxt(this.props.answer);
      if (data === true) {
        this.setState({ edit: false, Err: '' });
        this.props.handleSave(this.state.valueFor, this.props.answer);
      } else {
        this.setState({ Err: data });
      }
    } else {
      this.setState({ edit: false });
      this.props.handleSave(this.state.valueFor, this.props.answer);
    }
  };

   phonenumber = inputtxt => {
     if (inputtxt.length === 0) {
       return 'Mobile number should not be Empty';
     } else if (inputtxt.length < 12) {
       return 'Mobile number should not be less than 6 digits';
     } else {
       return true;
     }
   };

  NameInput = inputtxt => {
    const data = inputtxt.trim();
    if (data.length > 60) {
      return 'Should not exceed 60 characters.';
    } else {
      return true;
    }
  };

  handleDiscard = () => {
    this.setState({ edit: false, mobileNumberErr: '', genderErr: '' });
    this.props.handleDiscard(this.state.valueFor);
  };

  render() {
    const { profile } = this.props;
    const {
      gender, mobileNumberErr, genderErr, Err,
    } = this.state;
    return (
      <Row
        type='flex'
        className={ this.props.image ?
        'background-height labelspace water-label-height' :
        'background-base labelspace water-label' }
      >
        <Col xs={ 24 } md={ 8 } sm={ 24 }>
          <Row type='flex' className=' aligncenter'>
            <Col xs={ 23 }>
              <p className='log-Question'>{ this.props.question }</p>
            </Col>
          </Row>
        </Col>
        <Col xs={ 24 } md={ { span: 15, offset: 1 } } sm={ 24 }>
          { this.state.edit ?
            <Row type='flex' className='image-align'>
              <Col xs={ 24 } md={ 15 } sm={ 24 } className='userprofile-flex'>
                <Row className='d-flex profile-input-row text'>
                  <div className='verticallinewater profileFeildDesc' />
                  <div className=' d-flex text'>
                    <div className=' d-flex text'>
                      { this.props.image &&
                      <img
                        src={ this.props.image }
                        alt='bit'
                        className='Bitmap-image'
                      />
                    }
                      { this.state.valueFor === 'dob'
                      ?
                        <DatePicker
                          onChange={ e => this.props.handleChange(e, 'dob') }
                          format={ AppConfig.date_format2 }
                          value={ moment(this.props.answer) }
                        />
                      :
                        <Fragment>
                          {
                            this.state.valueFor === 'gender' ?
                              <Select
                                className='select-gender'
                                value={ gender[this.props.answer] }
                                onChange={ value => this.props.handleChange(value, this.state.valueFor) }
                              >
                                { gender.map((field, key) => (<Option value={ key }>{ field }</Option>)) }
                              </Select>
                            :
                              <Fragment>
                                {
                                  this.state.valueFor === 'mobileNumber' ?
                                    <div>dvfe</div>
                                    // <PhoneInput
                                    //     className={ this.props.image ? 'marginBottom log-answer' : 'log-answer' }
                                    //     value={this.props.answer === null ? '' : this.props.answer  }
                                    //     onChange={ value => this.props.handleChange(value, this.state.valueFor)}
                                    //     type={ this.state.valueFor === 'mobileNumber' && 'number'}
                                    //     countryCodeEditable={false}
                                    //     enableSearch={true}
                                    //     disableSearchIcon>
                                      // </PhoneInput>
                                :
                                    <Fragment>
                                      {
                                 this.state.valueFor === 'address' || this.state.valueFor === 'shortDescription' ?
                                   <Input.TextArea
                                     className={ this.props.image ? 'marginBottom log-answer' : 'log-answer text' }
                                     value={ this.props.answer }
                                     onChange={ e => this.props.handleChange(e.target.value, this.state.valueFor) }
                                   />
                               :
                                   <Input
                                     className={ this.props.image ? 'marginBottom log-answer' : 'log-answer ' }
                                     value={ this.props.answer }
                                     onChange={ e => this.props.handleChange(e.target.value, this.state.valueFor) }
                                     type={ this.state.valueFor === 'mobileNumber' && 'number' }
                                   /> }
                                    </Fragment>
                         }
                              </Fragment>
                     }
                        </Fragment>
                    }
                      {
                      this.state.valueFor === 'hobbies' &&
                      <span className='userprofile-hobbies-hint line'> Please input hobbies with comma separated</span>
                    }

                      {
                        (mobileNumberErr !== '' || genderErr !== '' || Err !== '') &&
                        <span className='has-error'>
                          <p className='ant-form-explain'>{ mobileNumberErr || genderErr || Err }</p>
                        </span>
                    }
                    </div>
                  </div>
                </Row>
              </Col>
              <Col span={ 6 } offset={ 1 } xs={ { span: 24, offset: 0 } } md={ 9 } sm={ { span: 24, offset: 0 } } className='align-right'>
                <Row type='flex' className='logsave'>
                  <div className='profile-save'>
                    <a
                      className='log-Edit'
                      onClick={ this.handleSave }
                    >Save
                    </a>
                  </div>
                  <div>
                    <a
                      className='log-Edit'
                      onClick={ this.handleDiscard }
                    >   Discard
                    </a>
                  </div>
                </Row>
              </Col>
            </Row>
              :
            <Row type='flex'>
              <Col xs={ 18 } className='userprofile-flex'>
                <Row className='d-flex height-100'>
                  <div className='verticallinewater profileFeildDesc' />
                  <div className='profile-align'>
                    <p className={ this.props.image ? 'marginBottom log-answer' : 'log-answer' }>{ this.getAnswers(this.state.valueFor, this.props.answer) } </p>
                  </div>
                </Row>
              </Col>
              { /* {
                  profile !== 'client' &&
                  <Col xs={ { span: 24 } } sm={ { span: 24 } } md={ { span: 4, offset: 1 } } className='justify-end align-center'>
                      <a
                        className='log-Edit'
                        onClick={ () => this.setState({ edit: true }) }
                      >Edit
                      </a>
                    </Col>
                } */ }
            </Row>
            }
        </Col>
      </Row>
    );
  }
}

export default UserProfileFields;
