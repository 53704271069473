import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Input, Button, Row, Form, Col, message, Spin } from 'antd';
import { withAppContext } from '../store/contextStore';
import { resetPassword } from '../api/users';

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.props.form.validateFields();
  }

    hasErrors=fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

    modifyPassword=e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const passwordInfo = Object.assign({}, { password: values.password, resetToken: this.props.match.params.id });
          resetPassword(passwordInfo).then(result => {
            if (result.success) {
              this.setState({ loading: false });
              message.success(`${result.data.message}`, 1, () => {
                this.props.history.push('/login');
              });
            } else {
              this.setState({ loading: false });
              const error = _.get(result.errors, 'message.error', false);
              const errMess = _.get(result.errors, 'message.message', false);
              let text;
              if (error) {
                text = error;
              } else if (errMess) {
                text = errMess;
              }
              message.error(`${text}`, 2);
              this.props.form.resetFields();
            }
          })
            .catch(err => {
              this.setState({ loading: false });
            });
        }
      });
    };

      compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
          callback('Two passwords that you enter is inconsistent!');
        } else {
          callback();
        }
      };

      handleOnChange = (element, value) => {
        this.props.form.setFieldsValue({
          [element]: value,
        });
      };

      render() {
        const {
          getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = this.props.form;
        const { loading } = this.state;
        const newPasswordError = isFieldTouched('password') && getFieldError('password');
        const confirmPasswordError = isFieldTouched('confirmPassword') && getFieldError('confirmPassword');

        return (
          <Row className='flex-page'>
            <Spin spinning={ loading }>
              <Col xs={ { span: 12, offset: 6 } }>
                <p className='changepassword-text'>Please reset your password below.</p>
                <Form onSubmit={ this.modifyPassword }>
                  <Form.Item
                    validateStatus={ newPasswordError ? 'error' : '' }
                    help={ newPasswordError || '' }
                    hasFeedback
                  >
                    { getFieldDecorator('password', {
               rules: [
                 {
                  required: true,
                  message: 'Please enter your new password!',
                 },
                 {
                    validator: this.validateToNextPassword,
                 },
             ],
             })(<Input
               className='input-base'
               type='password'
               placeholder='New Password'
               autoComplete='new-password'
               onChange={ e => this.handleOnChange('password', e.target.value) }
             />) }
                  </Form.Item>
                  <Form.Item
                    validateStatus={ confirmPasswordError ? 'error' : '' }
                    help={ confirmPasswordError || '' }
                    hasFeedback
                  >
                    { getFieldDecorator('confirmPassword', {
              rules: [
              {
                required: true,
                message: 'Please confirm your password!',
             },
             {
                validator: this.compareToFirstPassword,
             },
            ],
            })(<Input
              className='input-base'
              type='password'
              onBlur={ this.handleConfirmBlur }
              placeholder='Confirm new password'
              autoComplete='new-password'
              onChange={ e => this.handleOnChange('confirmPassword', e.target.value) }
            />) }
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType='submit' disabled={ this.hasErrors(getFieldsError()) }>
                      <span className='savepassword'>Save new password</span>
                    </Button>
                  </Form.Item>
                  <Link
                    to='/'
                    className='forgetpassword-Label'
                  >Login here
                  </Link>
                </Form>

              </Col>
            </Spin>
          </Row>
        );
      }
}

export default Form.create({ name: 'change_password' })(withRouter(withAppContext(ResetPassword)));

